html,
body,
div,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6,
img,
del,
em,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
footer,
header,
nav,
section,
audio,
video,
textarea,
select,
a,
button,
input {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
ol,
ul,
li,
dl,
dt,
dd {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
body {
  color: #333;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'PingFang SC', 'Helvetica Neue', STHeiti, 'Microsoft Yahei', Tahoma, Simsun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
legend {
  color: #333;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  border: none;
}
img[src=''],
img[src='null'],
img:not([src]) {
  opacity: 0;
}
a {
  color: #409eff;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  cursor: pointer;
}
a:hover,
a:active,
a:focus {
  outline: none;
}
input,
button,
select,
option,
textarea {
  appearance: none;
  outline: none;
  border: none;
  vertical-align: middle;
  border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
textarea {
  resize: none;
}
button,
label {
  cursor: pointer;
}
select::-ms-expand {
  display: none;
}
em,
i {
  font-style: normal;
  display: inline-block;
}
button {
  background-color: transparent;
}
/**
 * @Description: less全局变量/混入
 * @Description: 引入方式：@import "~@/assets/styles/var.less";
 * @Date: 2022-01-04
 * @LastEditTime: 2022-02-15
 */
html,
body {
  height: 100%;
}
#root {
  height: 100%;
  overflow: auto;
}
.one-txt-cut {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.two-txt-cut {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.c-PageLayout-headBar {
  height: 60px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0 auto;
  right: 0;
}
.c-PageLayout-headBar .header-wrapper {
  margin: 0 auto;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  position: relative;
  left: -10px;
}
.c-PageLayout-headBar .header-wrapper .headLeft {
  display: flex;
  align-items: center;
  height: 100%;
}
.c-PageLayout-headBar .header-wrapper .headLeft .toggleIcon {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}
.c-PageLayout-headBar .header-wrapper .headLeft .toggleIcon > .anticon {
  font-size: 18px;
}
.c-PageLayout-headBar .header-wrapper .headLeft .toggleIcon:hover {
  background: rgba(0, 0, 0, 0.02);
}
.c-PageLayout-headBar .header-wrapper .headRight {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 20px;
}
.c-PageLayout-headBar .header-wrapper .headRight img {
  position: relative;
  top: -8px;
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

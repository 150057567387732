.c-PageLayout-sideBar {
  height: 100%;
  overflow: auto;
}
.c-PageLayout-sideBar::-webkit-scrollbar {
  display: none;
}
.c-PageLayout-sideBar .sideBarLayout {
  min-height: 100%;
  background-color: #001529;
  user-select: none;
}
.c-PageLayout-sideBar .ant-layout-sider {
  background-color: #fff;
}
.c-PageLayout-sideBar .logoWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.c-PageLayout-sideBar .logoWrap .logo {
  height: 20px;
  width: 20px;
}
.c-PageLayout-sideBar .logoWrap .title {
  font-weight: normal;
  color: #fff;
  margin-left: 10px;
  white-space: nowrap;
  font-size: 16px;
}
.c-PageLayout-sideBar .menuWarp {
  height: calc(100vh - 50px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  writing-mode: vertical-lr;
  width: 100%;
}
.c-PageLayout-sideBar .menuWarp .menu-item {
  height: calc((100vh - 50px - 80px) / 3);
  text-align: center;
  font-size: 14px;
  letter-spacing: 12px;
  line-height: 60px;
  cursor: pointer;
}
.c-PageLayout-sideBar .menuWarp .product {
  color: #252525;
  background: linear-gradient(180deg, #f9f9f9 0%, #f3f3f3 100%);
}
.c-PageLayout-sideBar .menuWarp .product:hover {
  background: linear-gradient(180deg, #e1e0e0f9 0%, #dbdbdb 100%);
}
.c-PageLayout-sideBar .menuWarp .industry {
  background: linear-gradient(180deg, #2b84df 0%, #004f9f 100%);
  color: #fff;
}
.c-PageLayout-sideBar .menuWarp .industry:hover {
  background: linear-gradient(180deg, #5699dd 0%, #1f5a95 100%);
}
.c-PageLayout-sideBar .menuWarp .product-code {
  background: linear-gradient(180deg, #757787 0%, #454754 100%);
  color: #fff;
}
.c-PageLayout-sideBar .menuWarp .product-code:hover {
  background: linear-gradient(180deg, #7d7f89 0%, #4d4e51 100%);
}
.c-PageLayout-sideBar .menuWarp .search-button {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #ffbf42 0%, #f78902 100%);
  cursor: pointer;
}
.c-PageLayout-sideBar .menuWarp .search-button svg {
  font-size: 20px;
  line-height: 20px;
  fill: #fff;
}
.c-PageLayout-sideBar .menuWarp .search-button:hover {
  background: linear-gradient(180deg, #fcc65c 0%, #f89c2c 100%);
}

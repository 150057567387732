.v-login-index {
  background: url('../../assets/img/login_bg.png');
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.v-login-index .left-side {
  width: 60%;
}
@media (min-width: 61.9375rem) and (max-width: 100rem) {
  .v-login-index .left-side {
    width: 60%;
  }
}
@media (min-width: 48rem) and (max-width: 61.9375rem) and (orientation: landscape) {
  .v-login-index .left-side {
    /* landscape */
    width: 40%;
  }
}
@media (min-width: 48rem) and (max-width: 61.9375rem) and (orientation: portrait) {
  .v-login-index .left-side {
    width: 50%;
  }
}
@media (max-width: 35.9375rem) {
  .v-login-index .left-side {
    width: 90%;
  }
}
@media (min-width: 61.9375rem) and (max-width: 100rem) {
  .v-login-index .right-side {
    width: 40%;
  }
}
@media (min-width: 48rem) and (max-width: 61.9375rem) and (orientation: landscape) {
  .v-login-index .right-side {
    /* landscape */
    width: 60%;
  }
}
@media (min-width: 48rem) and (max-width: 61.9375rem) and (orientation: portrait) {
  .v-login-index .right-side {
    width: 50%;
  }
}
@media (max-width: 35.9375rem) {
  .v-login-index .right-side {
    width: 90%;
  }
}
.v-login-index .right-side .title {
  font-size: 18px;
  text-align: center;
  padding: 50px 0;
  letter-spacing: 1px;
}
@media (min-width: 61.9375rem) and (max-width: 100rem) {
  .v-login-index .right-side .title {
    padding-top: 80px;
  }
}
.v-login-index .right-side .formWrap {
  width: 350px;
  margin: 0 auto;
}
.v-login-index .right-side .formWrap .moreWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.v-login-index .right-side .formWrap .loginBtn {
  width: 100%;
}

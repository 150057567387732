.c-PageLayout-index {
  width: 100%;
  height: 100%;
  display: flex;
  min-width: 1250px;
}
.c-PageLayout-index .appMainWrap {
  height: 100%;
  flex: 1;
  position: relative;
  padding-top: 64px;
}
.c-PageLayout-index .appMain {
  height: 100%;
  overflow: auto;
}
.c-PageLayout-index .appMain .ant-pro {
  min-height: 840px;
}

.modal-home .ant-modal-content .ant-modal-close {
  right: -54px;
}
.modal-home .ant-modal-content .ant-modal-close .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: rgba(255, 255, 255, 0.2);
}
.modal-home .ant-modal-content .ant-modal-close .ant-modal-close-x .anticon {
  color: #fff;
}
.modal-home .ant-modal-content .ant-modal-body {
  padding: 0;
}
.modal-main {
  text-align: center;
}
.modal-main .form-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
}
.modal-main .form-main .formWrap {
  margin-top: 20px;
  width: 384px;
}
.modal-main .form-main .formWrap .info-title {
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
}
.modal-main .form-main .formWrap .ant-form-item {
  width: 384px;
}
.modal-main .form-main .formWrap .item-line {
  margin: 0 0 12px;
}
.modal-main .form-main .formWrap .moreWrap {
  display: flex;
  justify-content: right;
}
.modal-main .form-main .formWrap .moreWrap .btn-right a {
  color: #252525;
}
.modal-main .form-main .formWrap .ant-input:placeholder-shown {
  background: #f9f9f9;
  font-size: 14px;
  box-shadow: none;
}
.modal-main .form-main .formWrap .psd-input:hover {
  border-color: #004f9f;
  border-right-width: 1px;
}
.modal-main .form-main .formWrap .psd-input {
  padding: 9px 11px;
  font-size: 14px;
  position: relative;
  background: #f9f9f9;
}
.modal-main .form-main .formWrap .send-wait {
  position: absolute;
  right: 6px;
  z-index: 99;
  border: 1px solid #004f9f;
  padding: 4px;
  color: #004f9f;
}
.modal-main .form-main .formWrap .send {
  position: absolute;
  right: 6px;
  z-index: 99;
  border: 1px solid #004f9f;
  padding: 4px;
  color: #004f9f;
  cursor: no-drop;
}
.modal-main .form-main .formWrap .loginBtn {
  width: 384px;
  height: 44px;
  background: linear-gradient(180deg, #2b84df 0%, #004f9f 100%);
  cursor: pointer;
  color: #fff;
}
.modal-main .form-main .formWrap .loginBtn:hover,
.modal-main .form-main .formWrap .loginBtn:focus {
  background: linear-gradient(180deg, #5699dd 0%, #1f5a95 100%);
}
.modal-main .form-main .formWrap .checkout-Btn {
  width: 382px;
  height: 44px;
  background: #fff;
  border: 1px solid #004f9f;
  cursor: pointer;
  color: #004f9f;
}
.modal-main .form-main .formWrap .checkout-Btn:hover,
.modal-main .form-main .formWrap .checkout-Btn:focus {
  background-color: #004f9f;
  border: 1px solid #004f9f;
  color: #fff;
}
.modal-main .form-main .formWrap .login-register {
  font-weight: 400;
  font-size: 16px;
  color: #252525;
}
.modal-main .form-main .formWrap .login-register a {
  color: #004f9f;
  text-decoration: underline;
}
.modal-main .form-main .formWrap .btn-line {
  position: relative;
}
.modal-main .form-main .formWrap .btn-line span.line {
  position: absolute;
  display: inline-block;
  width: 170px;
  border-bottom: 1px solid rgba(37, 37, 37, 0.2);
  top: 15px;
}
.modal-main .form-main .formWrap .btn-line span.line-left {
  left: 0;
}
.modal-main .form-main .formWrap .btn-line span.line-right {
  right: 0;
}
.modal-main .form-main .formWrap .btn-line span.line-none {
  color: rgba(37, 37, 37, 0.5);
  line-height: 30px;
}
.modal-main .form-password {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.modal-main .form-password .header-title {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgba(37, 37, 37, 0.2);
  text-align: left;
  width: 100%;
  padding: 15px 30px;
}
.modal-main .form-password .password-content {
  width: 384px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
}
.modal-main .form-password .password-content .psd-title {
  text-align: left;
  margin-bottom: 8px;
}
.modal-main .form-password .password-content .psd-line {
  margin-top: 25px;
}
.modal-main .form-password .password-content .ant-input:placeholder-shown {
  background: #f9f9f9;
}
.modal-main .form-password .password-content .psd-input {
  padding: 9px 11px;
  font-size: 14px;
  position: relative;
  background: #f9f9f9;
}
.modal-main .form-password .password-content .psd-input::placeholder {
  font-size: 14px;
}
.modal-main .form-password .password-content .psd-input:hover {
  border-color: #004f9f;
  border-right-width: 1px;
}
.modal-main .form-password .password-content .psd-btn {
  width: 384px;
  height: 40px;
  background: linear-gradient(180deg, #2b84df 0%, #004f9f 100%);
  cursor: pointer;
  color: #fff;
  margin-top: 30px;
}
.modal-main .form-password .password-content .psd-btn:hover,
.modal-main .form-password .password-content .psd-btn:focus {
  background: linear-gradient(180deg, #5699dd 0%, #1f5a95 100%);
}
.modal-main .form-password .password-content .send-wait {
  position: absolute;
  right: 6px;
  z-index: 99;
  border: 1px solid #004f9f;
  padding: 4px;
  color: #004f9f;
}
.modal-main .form-password .password-content .send {
  position: absolute;
  right: 6px;
  z-index: 99;
  border: 1px solid #004f9f;
  padding: 4px;
  color: #004f9f;
  cursor: no-drop;
}
.modal-main .form-password .password-content .return-login {
  font-weight: 400;
  font-size: 14px;
  color: #252525;
  margin-top: 30px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 30px;
}
.modal-main .form-password .password-content .return-login a {
  color: #004f9f;
  text-decoration: underline;
}
.modal-main .reset-password {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.modal-main .reset-password .header-title {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgba(37, 37, 37, 0.2);
  text-align: left;
  width: 100%;
  padding: 15px 30px;
}
.modal-main .reset-password .password-content {
  width: 384px;
  text-align: left;
  margin-top: 30px;
}
.modal-main .reset-password .password-content .ant-input:placeholder-shown {
  background: #f9f9f9;
  border: 1px solid #d3d3d3;
}
.modal-main .reset-password .password-content .ant-input::placeholder {
  font-size: 14px;
}
.modal-main .reset-password .password-content .psd-title {
  text-align: left;
  margin-bottom: 8px;
}
.modal-main .reset-password .password-content .psd-line {
  margin-top: 25px;
}
.modal-main .reset-password .password-content .psd-input {
  padding: 9px 11px;
  font-size: 14px;
  position: relative;
}
.modal-main .reset-password .password-content .psd-input:hover {
  border-color: #004f9f;
  border-right-width: 1px;
}
.modal-main .reset-password .password-content .psd-btn {
  width: 384px;
  height: 40px;
  background: linear-gradient(180deg, #2b84df 0%, #004f9f 100%);
  cursor: pointer;
  color: #fff;
  margin-top: 30px;
}
.modal-main .reset-password .password-content .psd-btn:hover,
.modal-main .reset-password .password-content .psd-btn:focus {
  background: linear-gradient(180deg, #5699dd 0%, #1f5a95 100%);
}
.modal-main .reset-password .password-content .send-wait {
  position: absolute;
  right: 6px;
  z-index: 99;
  border: 1px solid #d3d3d3;
  padding: 3px;
}
.modal-main .reset-password .password-content .return-login {
  font-weight: 400;
  font-size: 14px;
  color: #252525;
  margin-top: 30px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 30px;
}
.modal-main .register-main .edit-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-main .register-main .edit-password div:nth-child(2) {
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: #004f9f;
}
.modal-main .register-main img {
  margin-top: 30px;
}
.modal-main .register-main .login-register {
  font-weight: 400;
  font-size: 16px;
  color: #252525;
  text-align: center;
}
.modal-main .register-main .login-register a {
  color: #004f9f;
  text-decoration: underline;
}
.modal-main .ant-input {
  background: #f9f9f9;
}
.modal-main .ant-input:focus {
  background: #f9f9f9;
}

.footer-main {
  font-family: 'Microsoft YaHei';
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footer-main .footer-content {
  max-width: 1100px;
  padding-top: 35px;
  margin-bottom: 35px;
  width: 100%;
  border-top: 1px solid #d3d3d3;
}
.footer-main .footer-content div.left-title {
  font-weight: 600;
  font-size: 20px;
  color: #004f9f;
  margin-bottom: 16px;
}
.footer-main .footer-content .footer-cont .footer-left div.left-detail {
  font-weight: 400;
  font-size: 12px;
  color: #252525;
  margin-bottom: 5px;
}
.footer-main .footer-content .footer-cont .footer-left div.left-detail .left-info {
  margin-bottom: 5px;
}
.footer-main .footer-content .footer-cont .footer-left div.left-phone {
  margin-right: 30px;
}
.footer-main .footer-content .footer-cont .footer-left .detail-content {
  display: flex;
}
.footer-main .footer-content .footer-cont .footer-right .right-href a {
  font-size: 16px;
  text-decoration-line: underline;
  color: #252525;
}
.footer-main .footer-content .footer-cont .footer-right .right-href a.right-link {
  margin-right: 50px;
}
.footer-main .footer-content .footer-cont .footer-right .right-tip {
  font-weight: 400;
  font-size: 12px;
  color: rgba(37, 37, 37, 0.5);
  margin-top: 44px;
}
